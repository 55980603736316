.navbar {
    background: $background-color;
    height: 64px;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    width: auto;
    transition: all 0.6s ease;
}

.navbar.scroll {
    background: $lightest-color;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);
}

.navbar-container {
    /* z-index: 10; */
    display: flex;
    justify-content: space-between;
    height: 64px;
    width: $small-container;
    margin: auto;
}

.navbar-logo {
    color: $dark-color;
    cursor: pointer;
    text-decoration: none;
    font-size: 1em;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.navbar-logo:hover {
    color: $darkest-color;
    transform: scale(1.1);
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    text-align: center;
    justify-content: flex-end;
}
  
.nav-item {
    margin: 32px 0;
}
  
.nav-links {
    color: $light-color;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    transition: all 0.2s ease-in-out;
}

.nav-links:hover {
    color: $darkest-color;
    transform: scale(1.1);
}

.menu-icon {
    display: none;
}

.external-link-icon {
    font-size: 0.75rem;
    position: relative;
    left: 4px;
}

@media screen and (max-width: $medium-container) {

    .navbar-logo {
        z-index: 100;
    }
  
    .nav-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100vh;
        position: absolute;
        left: -100%;
        transition: all 0.5s ease;
        z-index: 90;
    }
  
    .nav-menu.active {
        background: $lightest-color;
        left: 0;
        transition: all 0.6s ease;
        z-index: 90;
    }

    .navbar-container {
        width: auto;
        justify-content: center;
    }
  
    .nav-links {
        text-align: center;
        width: 100%;
        display: flex;
    }

    .menu-icon {
        z-index: 100;
        display: block;
        position: absolute;
        top: 16px;
        right: 16px;
        /* transform: translate(-100%, 50%); */
        font-size: 1.85rem;
        cursor: pointer;
        color: $dark-color;
        transition: all .2s ease-in-out;
    }

    .menu-icon:hover {
        color: $darkest-color;
        transform: scale(1.1);
    }

}