.page {
    background-color: $background-color;  

    display: flex;
    justify-content: center;
    align-items: center;

    overflow-x: auto;
}

.page-container {
    justify-content: center;
    padding: $medium-spacer;
    overflow: visible;
}

.page-preview {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: $large-container) {
    .page {
        display: block;
    }

    .page-container {
        display: inline-block;
    }

}