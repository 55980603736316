.footer {
    background-color: $lightest-color;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.5);

    position: relative;
    /* bottom: 0; */
    z-index: 98;

    margin: auto;
    height: 100%;
}

.footer-container {
    width: $small-container;
    margin: auto;
    display: flex;
    justify-content: center;
}

.social {
    padding: 32px;
    width: 200px;
}

.social h1 {
    color: $medium-color;
    font-size: 1.5em;
    font-weight: 300;
    padding: 16px 0;
}

.social ul { 
    color: $light-color;
    list-style: none;
    padding-bottom: 32px;
    
}

.social li {
    padding-bottom: 4px;
}

.social li a:hover {
    color: $darkest-color;
}
.social li a {
    transition: all .2s ease-in-out;
    color: $light-color;
    text-decoration: none;
}

.about {
    padding: 32px;
    width: 200px;
}

.about h1 {
    color: $medium-color;
    font-weight: 300;
    font-size: 1.5em;
    padding: 16px 0;
}

.about p {
    color: $light-color;
}

@media screen and (max-width: $medium-container) {
    .footer-container {
        max-width: 100%;
        width: 100%;
    }
}